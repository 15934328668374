// import Link from 'next/link'

// import { Container } from '@/components/Container'

import { Link } from "react-router-dom";
import { Container } from "../Container";
import { siteSettings } from "../../settings";
// Link

const faqs = [
  [
    {
      question: "How do I open an account?",
      answer:
        "Our account registration process is as easy as clicking a 'Start Pairs Trading' button anywhere on our website. They will all take you to the account registration page, or your dashboard if you have already logged in.",
    },
    {
      question: "How do I make a deposit?",
      answer:
        "Once logged into your account, you will find a 'deposit' panel as an option in the sidebar of your dashboard. This will enable you deposit really quickly.",
    },
  ],
  [
    {
      question: "What is copy trading?",
      answer:
        "Copy trading is most useful for those that do not wish to trade on their own or with an account manager. With copy trading, you can select from a list of specially selected traders and directly copy every trade they place.",
    },
    {
      question: "How does staking work?",
      answer:
        "Staking your crypto allows you to tap into the power of the blockchain decentralization and make profits. Your crypto is placed in a pool of traders and you earn on daily basis in the pool.",
    },
    // {
    //   question: "Is there any age limit to trading on Pocket?",
    //   answer:
    //     "For our free plan, the age limit is based on the minimum age to trade in your country of residence. Our VIP plan uses advanced transaction anonymization though, so you can use that plan even if you’re 9 years old. Or a dog.",
    // },
  ],
  [
    {
      question: "What trading options are available?",
      answer:
        "We offer a wide vareity of options spanning accross stock pairs trading, cryptocurrencies staking and trading.",
    },
    // {
    //   question: "How do I invest in real estate?",
    //   answer:
    //     "We provide an out-of-the-box solution to earning from real estate. You can earn without even having to buy and keep property with the available options when you create an account.",
    // },
    // {
    //   question: "How do I become an insider?",
    //   answer:
    //     "Contact us with some details about your industry and the type of access you have to apply for an insider account. Once approved, we’ll send you a guide on collecting insider information without being detected at work.",
    // },
  ],
];

export function FAQTwo() {
  return (
    <section
      id="faqs"
      aria-labelledby="faqs-title"
      className="border-gray-200 py-20 sm:py-32"
    >
      <Container>
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="faqs-title"
            className="text-3xl font-medium tracking-tight text-white"
          >
            Frequently asked questions
          </h2>
          <p className="mt-2 text-lg text-gray-300">
            If you have anything else you want to ask,{" "}
            <Link
              to={`mailto:${siteSettings.email}`}
              className="text-gray-400 underline"
            >
              reach out to us
            </Link>
            .
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 sm:mt-20 lg:max-w-none lg:grid-cols-3"
        >
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role="list" className="space-y-10">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="text-lg font-semibold leading-6 text-white">
                      {faq.question}
                    </h3>
                    <p className="mt-4 text-sm text-gray-300">{faq.answer}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
}
