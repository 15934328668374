// import {
//   CloudArrowUpIcon,
//   LockClosedIcon,
//   ServerIcon,
// } from "@heroicons/react/20/solid";

import { siteSettings } from "../../settings";

export default function AboutTwo() {
  return (
    <div className="relative isolate overflow-hidden bg-[#05080b] py-24 sm:py-32">
      <div
        className="absolute -top-80 left-[max(6rem,33%)] -z-10 transform-gpu blur-3xl sm:left-1/2 md:top-20 lg:ml-20 xl:top-3 xl:ml-56"
        aria-hidden="true"
      ></div>
      <div className="mx-auto max-w-[1545px] px-6 lg:px-8">
        <div className="mx-auto max-w-4xl lg:mx-0">
          <p className="text-[14px] font-semibold leading-8 tracking-tight text-[#0C6CF2]">
            About us
          </p>
          <h1 className="mt-2 sm:text-4xl text-3xl font-[600] tracking-tight text-[white] ">
            We bring honesty and transparency to financial services technology
          </h1>
          {/* <p className="mt-6 text-xl leading-8 text-white">
            Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem. At
            arcu, sit dui mi, nibh dui, diam eget aliquam. Quisque id at vitae
            feugiat egestas ac. Diam nulla orci at in viverra scelerisque eget.
            Eleifend egestas fringilla sapien.
          </p> */}
        </div>
        <div className="mx-auto mt-16 grid max-w-4xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:mt-10 lg:max-w-none lg:grid-cols-12">
          <div className="relative lg:order-last lg:col-span-5">
            <svg
              className="absolute -top-[40rem] left-1 -z-10 h-[64rem] w-[175.5rem] -translate-x-1/2 stroke-[white]/10 [mask-image:radial-gradient(64rem_64rem_at_111.5rem_0%,white,transparent)]"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e87443c8-56e4-4c20-9111-55b82fa704e3"
                  width={200}
                  height={200}
                  patternUnits="userSpaceOnUse"
                >
                  <path d="M0.5 0V200M200 0.5L0 0.499983" />
                </pattern>
              </defs>
              <rect
                width="100%"
                height="100%"
                strokeWidth={0}
                fill="url(#e87443c8-56e4-4c20-9111-55b82fa704e3)"
              />
            </svg>

            {/* <figure>
              <div className="aspect-h-7 aspect-w-12 lg:aspect-none">
                <img
                  className="rounded-lg object-cover object-center shadow-lg"
                  src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&auto=format&fit=crop&w=1567&q=80"
                  alt="Office"
                />
              </div>
            </figure> */}

            {/* <figure className="border-l border-[#0C6CF2] pl-8">
              <blockquote className="text-xl font-semibold leading-8 tracking-tight text-white">
                <p>
                  “Vel ultricies morbi odio facilisi ultrices accumsan donec
                  lacus purus. Lectus nibh ullamcorper ac dictum justo in
                  euismod. Risus aenean ut elit massa. In amet aliquet eget
                  cras. Sem volutpat enim tristique.”
                </p>
              </blockquote>
              <figcaption className="mt-8 flex gap-x-4">
                <img
                  src="https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                  className="mt-1 h-10 w-10 flex-none rounded-full bg-gray-50"
                />
                <div className="text-sm leading-6">
                  <div className="font-semibold text-white">Brenna Goyette</div>
                  <div className="text-white">@brenna</div>
                </div>
              </figcaption>
            </figure> */}
          </div>
          <div className="max-w-4xl text-[18px] leading-7 text-white lg:col-span-7">
            <p>
              Welcome to {siteSettings.name}, an international online trading
              firm offering 24 hours access to a diverse range of trading
              instruments.
              <br />
              <br />
              Through our {siteSettings.name} platform, you can take advantage
              of high liquidity, low spreads, mobile trading, technical analysis
              and even more.
            </p>
            {/* <ul role="list" className="mt-8 max-w-xl space-y-8 text-white">
              <li className="flex gap-x-3">
                <CloudArrowUpIcon
                  className="mt-1 h-5 w-5 flex-none text-[#0C6CF2]"
                  aria-hidden="true"
                />
                <span>
                  <strong className="font-semibold text-[#CECFD1]">
                    Push to deploy.
                  </strong>{" "}
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Maiores impedit perferendis suscipit eaque, iste dolor
                  cupiditate blanditiis ratione.
                </span>
              </li>
              <li className="flex gap-x-3">
                <LockClosedIcon
                  className="mt-1 h-5 w-5 flex-none text-[#0C6CF2]"
                  aria-hidden="true"
                />
                <span>
                  <strong className="font-semibold text-[#CECFD1]">
                    SSL certificates.
                  </strong>{" "}
                  Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure
                  qui lorem cupidatat commodo.
                </span>
              </li>
              <li className="flex gap-x-3">
                <ServerIcon
                  className="mt-1 h-5 w-5 flex-none text-[#0C6CF2]"
                  aria-hidden="true"
                />
                <span>
                  <strong className="font-semibold text-[#CECFD1]">
                    Database backups.
                  </strong>{" "}
                  Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus.
                  Et magna sit morbi lobortis.
                </span>
              </li>
            </ul> */}
            <p className="mt-8">
              We maintain high standards to keep trading environments secure, 
              and offer the best tailor made trading conditions for every client. 
              We help traders and investors develop the knowledge and skills they 
              need to trade more efficiently and responsibly.
            </p>

            <p className="mt-8">
              We aspire to do this by building fine products that can help boost
              business activity, remove inefficiencies and provide better
              performance and growth.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

{
  /* <h2 className="mt-16 text-2xl font-bold tracking-tight text-[#CECFD1]">
              No server? No problem.
            </h2>
            <p className="mt-6">
              Id orci tellus laoreet id ac. Dolor, aenean leo, ac etiam
              consequat in. Convallis arcu ipsum urna nibh. Pharetra, euismod
              vitae interdum mauris enim, consequat vulputate nibh. Maecenas
              pellentesque id sed tellus mauris, ultrices mauris. Tincidunt enim
              cursus ridiculus mi. Pellentesque nam sed nullam sed diam turpis
              ipsum eu a sed convallis diam.
            </p> */
}
