import { siteSettings } from "../../settings";
import { Button } from "../Button";

import {
  LockClosedIcon,
  BoltIcon,
  ChatBubbleOvalLeftIcon,
} from "@heroicons/react/20/solid";

const features = [
  {
    name: "Proven Strategies.",
    description:
      "Experience trading success with a combination of advanced, data-driven strategies and personalized support from expert managers, tailored to help you achieve consistent, sustainable results.",
    icon: BoltIcon,
  },
  {
    name: "Secured.",
    description:
      "Utilising top-tier security practices, our platform is able to handle cyberattacks and protect your assets.",
    icon: LockClosedIcon,
  },
  {
    name: "Support.",
    description:
      "Our representatives are available to assist you at anytime. Or reach us 24/7/365 on Live Chat or by email.",
    icon: ChatBubbleOvalLeftIcon,
  },
];

export default function WhiteMidCTATwo() {
  return (
    <div className="bg-white" style={{ display: "grid", placeItems: "center" }}>
      <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          {/* <p className="text-left">
            We care very deeply about our work and the kind of impacts we can
            make with it. We have a very few sets of beliefs and strongly-held
            ideas we're betting on, about the future of enterprise and commerce
            in emerging economies.
            <br />
            <br />
            We are, therefore, building commerce-enabling financial products,
            from the ground up, stacks of new services, honest and very
            critical, to help local businesses become more successful.
            <br />
            <br />
            We serve thousands of businesses today but we are just getting
            started. They share their stories on how much Brass has done. We
            will continue to work to make entrepreneurship more permissionless
            and successful on the continent and everywhere else it is most
            needed.
          </p> */}

          <h2 className="text-3xl font-[600] tracking-tight text-gray-900 sm:text-4xl">
            {/* Boost your productivity. */}
            {/* <br /> */}
            {/* Start using our app today. */}
            Start Pairs Trading Today
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
            It’s never been easier to start pairs trading. Register an account with us and get
            started with trading today.
          </p>
        </div>
        <br />
        <br />
        <br />

        <div className="max-w-[1545px] px-6 lg:px-8">
          <dl className="grid grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-9">
                <dt className="inline font-semibold text-gray-900">
                  <feature.icon
                    className="absolute left-1 top-1 h-5 w-5 text-[#0C6CF2]"
                    aria-hidden="true"
                  />
                  {feature.name}
                </dt>{" "}
                <dd className="inline">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
        <br />
        {/* <br /> */}
        {/* <FeaturesSix />
         */}
        <div className="mt-10 flex items-center justify-center gap-x-6">
          {/* <a
              href="#"
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Get started
            </a>
            <a
              href="#"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Learn more <span aria-hidden="true">→</span>
            </a> */}
          <Button href={`${siteSettings.appLink}/register`}>Start Pairs Trading</Button>
        </div>
      </div>
    </div>
  );
}
