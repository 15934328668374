const StatsThree = () => {
  return (
    <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
      <div className="mx-auto max-w-2xl lg:mx-0">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          We're changing the way people earn and invest, one trade at a time
        </h2>
        <p className="mt-6 text-base leading-7 text-gray-600">
          We empower individuals from all walks of life to take control of their
          financial futures, making trading accessible, transparent, and
          rewarding. But don't just take our word for it. The numbers tell a
          compelling story, reflecting the tangible impact we've made on the
          lives of countless investors.
        </p>
      </div>
      <div className="mx-auto mt-16 flex max-w-2xl flex-col gap-8 lg:mx-0 lg:mt-20 lg:max-w-none lg:flex-row lg:items-end">
        <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-50 p-8 sm:w-3/4 sm:max-w-md sm:flex-row-reverse sm:items-end lg:w-72 lg:max-w-none lg:flex-none lg:flex-col lg:items-start">
          <p className="flex-none text-3xl font-bold tracking-tight text-gray-900">
            $100M+
          </p>
          <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
            <p className="text-lg font-semibold tracking-tight text-gray-900">
              Paid out to traders
            </p>
            <p className="mt-2 text-base leading-7 text-gray-600">
              We have successfully paid out over US $100 million to our investors and traders, 
              underscoring our commitment to prompt and reliable transactions.
            </p>
          </div>
        </div>
        <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-900 p-8 sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-sm lg:flex-auto lg:flex-col lg:items-start lg:gap-y-44">
          <p className="flex-none text-3xl font-bold tracking-tight text-white">
            42+
          </p>
          <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
            <p className="text-lg font-semibold tracking-tight text-white">
              Countries registered with us
            </p>
            <p className="mt-2 text-base leading-7 text-gray-400">
              With registrations spanning across 42+ countries, our platform
              stands as a testament to global reach and diversity.
            </p>
          </div>
        </div>
        <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-[#0C6CF2] p-8 sm:w-11/12 sm:max-w-xl sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-none lg:flex-auto lg:flex-col lg:items-start lg:gap-y-28">
          <p className="flex-none text-3xl font-bold tracking-tight text-white">
            13M+
          </p>
          <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
            <p className="text-lg font-semibold tracking-tight text-white">
              Volume of trades monthly
            </p>
            <p className="mt-2 text-base leading-7 text-indigo-200">
              Our platform is proud to host over 13 million volumes of trades
              monthly, a figure that showcases both the vibrant activity and the
              trust that our clients place in our services.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsThree;
