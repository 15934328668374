import { useId } from "react";
// import Image from 'next/image'
import { Tab } from "@headlessui/react";
import clsx from "clsx";

// import { Container } from '@/components/Container'
import { Container } from "../Container";

const features = [
  {
    name: "Advance Pair Trading Analytics",
    summary:
      "Gain an edge in your trading with Pair Dex’s advanced analytics suite.",
    description:
      "Gain an edge in your trading with Pair Dex’s advanced analytics suite. Dive deep into real-time market data, explore comprehensive trend analyses, and receive actionable insights tailored for optimal pair trading strategies. Empower your financial decisions with tools that simplify complex data.",
    icon: function ReportingIcon() {
      let id = useId();
      return (
        <>
          <defs>
            <linearGradient
              id={id}
              x1="11.5"
              y1={18}
              x2={36}
              y2="15.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset=".194" stopColor="#fff" />
              <stop offset={1} stopColor="#6692F1" />
            </linearGradient>
          </defs>
          <path
            d="m30 15-4 5-4-11-4 18-4-11-4 7-4-5"
            stroke={`url(#${id})`}
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
    },
  },
  {
    name: "Secure Assets Management",
    summary:
      "Securely manage a diverse portfolio with Pair Dex’s intuitive platform.",
    description:
      "Securely manage a diverse portfolio with Pair Dex’s intuitive platform. Track, analyze, and optimize your paired assets effortlessly. With personalized safeguards and a streamlined interface, you can manage risk and enhance performance—all from one place.",
    icon: function InventoryIcon() {
      return (
        <>
          <path
            opacity=".5"
            d="M8 17a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z"
            fill="#fff"
          />
          <path
            opacity=".3"
            d="M8 24a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z"
            fill="#fff"
          />
          <path
            d="M8 10a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z"
            fill="#fff"
          />
        </>
      );
    },
  },
  {
    name: "24/7 Expert Support",
    summary:
      "Trade with confidence knowing that our dedicated support team is here for you anytime, day or night.",
    description:
      "Trade with confidence knowing that our dedicated support team is here for you anytime, day or night. Our dedicated team of knowledgeable representatives is always ready to assist you with any concerns, or support you may need at any time of day. ",
    icon: function ContactsIcon() {
      return (
        <>
          <path
            opacity=".5"
            d="M25.778 25.778c.39.39 1.027.393 1.384-.028A11.952 11.952 0 0 0 30 18c0-6.627-5.373-12-12-12S6 11.373 6 18c0 2.954 1.067 5.659 2.838 7.75.357.421.993.419 1.384.028.39-.39.386-1.02.036-1.448A9.959 9.959 0 0 1 8 18c0-5.523 4.477-10 10-10s10 4.477 10 10a9.959 9.959 0 0 1-2.258 6.33c-.35.427-.354 1.058.036 1.448Z"
            fill="#fff"
          />
          <path
            d="M12 28.395V28a6 6 0 0 1 12 0v.395A11.945 11.945 0 0 1 18 30c-2.186 0-4.235-.584-6-1.605ZM21 16.5c0-1.933-.5-3.5-3-3.5s-3 1.567-3 3.5 1.343 3.5 3 3.5 3-1.567 3-3.5Z"
            fill="#fff"
          />
        </>
      );
    },
  },
  {
    name: "Security You Can Trust",
    summary:
      "At Pair Dex, your data and assets are protected by top-tier security protocols.",
    description:
      "At Pair Dex, your data and assets are protected by top-tier security protocols. Our robust encryption methods, regular audits, and advanced security measures ensure your trading experience remains safe and private. ",
    icon: function ShieldIcon() {
      return (
        <>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18.5292 8.20411C18.3863 8.07305 18.1969 8 18 8C17.8031 8 17.6137 8.07305 17.4708 8.20411C15.2936 10.2042 12.394 11.3011 9.3918 11.2601C9.22825 11.258 9.06827 11.3064 8.93511 11.3983C8.80195 11.4902 8.70253 11.6208 8.65129 11.771C8.21844 13.0472 7.99859 14.3818 8.00001 15.7249C8.00001 21.6205 12.1682 26.5725 17.8082 27.9765C17.934 28.0078 18.066 28.0078 18.1918 27.9765C23.8318 26.5725 28 21.6205 28 15.7249C28 14.3458 27.7713 13.0162 27.3487 11.771C27.2977 11.6206 27.1983 11.4898 27.0651 11.3977C26.932 11.3056 26.7719 11.2571 26.6082 11.2591L26.4615 11.2601C23.3887 11.2601 20.598 10.0992 18.5292 8.20411ZM21.7026 16.1575C21.7641 16.0782 21.8086 15.9878 21.8335 15.8915C21.8584 15.7953 21.8632 15.6953 21.8475 15.5973C21.8319 15.4993 21.7962 15.4052 21.7424 15.3208C21.6887 15.2363 21.618 15.1631 21.5346 15.1054C21.4512 15.0478 21.3567 15.0068 21.2567 14.985C21.1567 14.9631 21.0532 14.9608 20.9523 14.9782C20.8514 14.9957 20.755 15.0324 20.669 15.0863C20.583 15.1403 20.5089 15.2103 20.4513 15.2923L17.1323 19.7869L15.4667 18.1756C15.3209 18.0442 15.128 17.9726 14.9287 17.976C14.7294 17.9794 14.5393 18.0575 14.3983 18.1939C14.2574 18.3302 14.1767 18.5141 14.1732 18.7069C14.1696 18.8997 14.2436 19.0863 14.3795 19.2273L16.6872 21.4598C16.7661 21.5361 16.8613 21.5949 16.9661 21.6321C17.071 21.6692 17.1829 21.6839 17.2941 21.675C17.4054 21.6661 17.5133 21.6339 17.6104 21.5807C17.7075 21.5274 17.7914 21.4543 17.8564 21.3665L21.7026 16.1575Z"
            fill="white"
          />
        </>
      );
    },
  },
];

function Feature({ feature, isActive, className, ...props }) {
  return (
    <div
      className={clsx(className, !isActive && "opacity-75 hover:opacity-100")}
      {...props}
      style={{
        textAlign: "center",
        justifyItems: "center",
        display: "grid",
      }}
    >
      <div
        className={clsx(
          "w-9 h-9 rounded-lg flex",
          isActive ? "bg-blue-600" : "bg-slate-500"
        )}
      >
        <svg
          aria-hidden="true"
          className="h-9 w-9 grid items-center"
          fill="none"
        >
          <feature.icon />
        </svg>
      </div>
      <h3
        className={clsx(
          "mt-6 text-[18px] font-medium",
          isActive ? "text-blue-600" : "text-slate-600"
        )}
      >
        {feature.name}
      </h3>
      {/* <p className="mt-2 font-sans text-xl text-slate-900">{feature.summary}</p> */}
      <p className="mt-2 text-[16px] text-slate-600">{feature.description}</p>
    </div>
  );
}

function FeaturesMobile() {
  return (
    <div className="-mx-4 mt-20 flex flex-col gap-y-10 overflow-hidden px-4 sm:-mx-6 sm:px-6 lg:hidden">
      {features.map((feature) => (
        <div key={feature.name}>
          <Feature feature={feature} className="mx-auto max-w-2xl" isActive />
          <div className="relative mt-10 pb-10"></div>
        </div>
      ))}
    </div>
  );
}

function FeaturesDesktop() {
  return (
    <Tab.Group as="div" className="hidden lg:mt-20 lg:block">
      {({ selectedIndex }) => (
        <>
          <Tab.List className="grid grid-cols-4 gap-x-8">
            {features.map((feature, featureIndex) => (
              <Feature
                key={feature.name}
                feature={{
                  ...feature,
                  name: (
                    <Tab className="[&:not(:focus-visible)]:focus:outline-none">
                      <span className="absolute inset-0" />
                      {feature.name}
                    </Tab>
                  ),
                }}
                isActive
                className="relative"
              />
            ))}
          </Tab.List>
        </>
      )}
    </Tab.Group>
  );
}

export function FeaturesOne() {
  return (
    <section
      id="secondary-features"
      aria-label="Features for simplifying everyday business tasks"
      className="pb-14 pt-14 sm:pb-20 sm:pt-24 lg:pb-32"
    >
      <Container>
        {/* <div className="mx-auto max-w-2xl md:text-center">
          <h2 className="font-sans font-semibold text-[45px] tracking-tight text-slate-900 sm:text-4xl">
            Financial markets, demistified
          </h2>
          <p className="mt-4 text-[18px] tracking-tight text-slate-700">
            Making financial markets understandable and accessible to everyone,
            one trade at a time.
          </p>
        </div> */}
        <FeaturesMobile />
        <FeaturesDesktop />
      </Container>
    </section>
  );
}
