import ContentTwo from "../components/content/ContentTwo";
import ContentTwoDark from "../components/content/ContentTwoDark";
import CTAFour from "../components/cta/CTAFour";
import CTAThree from "../components/cta/CTAThree";
import { CTAOne } from "../components/cta/CtaOne";
import WhiteMidCTA from "../components/cta/WhiteMidCTA";
import WhiteMidCTANoText from "../components/cta/WhiteMidCTANoText";
import FAQThree from "../components/faq/FAQThree";
import FooterOne from "../components/footer/FooterOne";
import { Header } from "../components/header/Header";
import TitleFive from "../components/section-title/TitleFive";
import TitleFour from "../components/section-title/TitleFour";
import TitleOne from "../components/section-title/TitleOne";
import TitleThree from "../components/section-title/TitleThree";
import TitleTwo from "../components/section-title/TitleTwo";
import StatsThree from "../components/stats/StatsThree";

const faqs = [
  {
    question: "What Are Stocks?",
    answer:
      "Stocks, also commonly referred to as equities or shares, are issued by a public corporation and put up for sale. Companies originally used stocks as a way of raising additional capital, and as a way to boost their business growth. When the company first puts these stocks up for sale, this is called the Initial Public Offering. Once this stage is complete, the shares themselves are then sold on the stock market, which is where any stock trading will occur.",
  },
  {
    question: "How Do I Trade Stocks?",
    answer:
      "A stock market is where stocks are traded: where sellers and buyers come to agree on a price. Historically, stock exchanges existed in a physical location, and all transactions took place on the trading floor. One of the world’s most famous stock markets is the London Stock Exchange (LSE). Our platform provides an alternative to trading by bringing all of the tools required to trade, into one",
  },

  {
    question: "Stock Trading Risk Assessment",
    answer:
      "All forms of financial investment carry a level of risk, and stock trading is no different. Even traders with decades of experience cannot predict the correct price movements every single time. People use various strategies, but it is important to note that there is no such thing as a failsafe strategy. It is also advisable to limit the amount of money you invest in a single trade, as part of your own risk management.",
  },
  
  {
    question: "What Is Margin?",
    answer:
      "Margin is the amount of a trader’s funds required to open a new position. Margin is estimated based on the size of your trade, which is measured in lots. A standard lot is 100,000 units. We also provide mini lots (10,000 units), micro lots (1,000 units) and nano lots (100 units). The greater the lot, the bigger the margin amount. Margin allows you to trade with leverage, which, in turn, allows you to place trades larger than the amount of your trading capital. Leverage influences the margin amount too.",
  },

  {
    question: "What Is Leverage?",
    answer:
      "Leverage is the ability to trade positions larger than the amount of capital you possess. This mechanism allows traders to use extra funds from a broker in order to increase the size of their trades. For example, 1:100 leverage means that a trader who has deposited $1,000 into his or her account can trade with $100,000. Although leverage lets traders increase their trade size and, consequently, potential gains, it magnifies their potential losses putting their capital at risk.",
  },

  {
    question: "When Is The Stock Market Open?",
    answer:
      "The stock market typically operates during specific hours based on the time zone of the exchange. In the United States, major stock exchanges such as the New York Stock Exchange (NYSE) and NASDAQ are open from 9:30 a.m. to 4:00 p.m. Eastern Standard Time (EST) from Monday to Friday, excluding holidays. Pre-market and after-hours trading sessions are also available, generally from 4:00 a.m. to 9:30 a.m. EST for pre-market, and from 4:00 p.m. to 8:00 p.m. EST for after-hours. However, these extended hours may have lower trading volumes compared to regular market hours.",
  },
];

const Stock = () => {
  return (
    <>
      <div style={{ backgroundColor: "#05080b" }}>
        <Header />
      </div>
      <TitleFive />
      <StatsThree />
      <br />
      <br />
      <br />
      <FAQThree faqs={faqs} />
      <WhiteMidCTANoText />
      {/* <div style={{ backgroundColor: "#05080b" }}>
        <ContentTwoDark />
      </div> */}

      {/* <CTAThree /> */}
      <FooterOne />
    </>
  );
};

export default Stock;
