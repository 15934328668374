import {
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";
import { siteSettings } from "../../settings";

export default function TermsText() {
  return (
    <div className="bg-white px-6 py-32 lg:px-8">
      <div className="mx-auto max-w-4xl text-base leading-7 text-gray-700">
        {/* <p className="text-base font-semibold leading-7 text-indigo-600">
              Introducing
            </p> */}
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Our terms of fair usage and service
        </h1>
        <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
          Introduction
        </h2>
        <p className="mt-6 leading-8">
          This Agreement is entered into by and between {siteSettings.name} LTD (hereinafter referred to as the “Company”) 
          and the user (a natural or legal entity) (hereinafter referred to as the “Client”). 
          By accessing or registering an Account on Pairdex.io (the “Website”), 
          the Client agrees to comply with all the terms and conditions outlined in this Agreement and any associated policies, 
          including but not limited to the Privacy Policy, Payment Policy, Withdrawal Policy, Code of Conduct, Order Execution Policy, 
          and Anti-Money Laundering (AML) Policy. Acceptance of these terms constitutes a legally binding agreement between the Client and the Company, 
          regulating the use of the Company’s Trading Platform and services.
        </p>

        <div>
          <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
            Terms
          </h2>
          <p className="mt-6">
            • Account: A unique, personalized account registered in the Client’s name, storing all transactions, operations, and activities conducted on the Trading Platform. <br/>
            • Trading Platform: The electronic system provided by the Company that facilitates real-time trading, quote displays, and order management.  <br/>
            • Copy Trading: A feature enabling the Client to replicate the trading strategies of other users on the platform.  <br/>
            • Staking: The process of locking cryptocurrency assets to earn rewards under specified conditions and APY rates.  <br/>
            • COT (Cost of Transfer): Variable fees impacting the withdrawal process, determined by factors such as blockchain traffic and third-party charges. <br/>
            • KYC Documents: Identification and verification documents, including but not limited to passports, government IDs, and proof of residence, required for regulatory compliance. <br/>
            • Spread: The difference between the Ask (buy) and Bid (sell) prices for a financial instrument at the same moment.  <br/>
            • Financial Instruments: Tradeable instruments defined under applicable regulatory standards and offered through the Company’s Trading Platform.  <br/>

          </p>
        </div>

        <div>
        <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
            Regulatory Compliance
          </h2>
          <p className="mt-6">
          The Company operates under regulatory compliance standards set by the United States, European Union, and Australia. The Client agrees to adhere to all 
          applicable local and international regulations and laws governing financial transactions and trading practices.
          </p>
        </div>

        <div>
          <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
            Subject Of The Agreement
          </h2>
          <p className="mt-2">
          The Company provides access to financial trading, cryptocurrency staking,
           and related services through its Trading Platform on an execution-only basis. 
           The Client acknowledges that the Company does not manage or offer advisory 
           services for the Client’s account, nor does it bear responsibility for the 
           outcome of any trading decisions made by the Client. All activities conducted on the
           Trading Platform are subject to the terms of this Agreement and relevant documentation published on the Website.
          </p>
        </div>

        <div>
          <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
            General Provisions
          </h2>
          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Service Scope</h3>
          <p className="mt-2">
            The Company shall offer services at its discretion, including, but not limited to:<br/>

            • Access to real-time trading and execution of orders.<br/>
            • Copy trading services that facilitate the duplication of other traders’ activities.<br/>
            • Cryptocurrency staking and conversion services.<br/>
            • Access to news, analytics, and trading data.<br/>

            The Client acknowledges that services provided by the Company do not include investment advice, 
            portfolio management, or guaranteed returns.
          </p>

          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Deposit Allocation</h3>
          <p className="mt-2">
          • Specific Allocation: All deposits made by the Client must be clearly allocated to their respective sections (e.g., investment plans or signal purchases). 
          Misallocations are prohibited and may require administrative adjustments, which could incur additional fees.<br/>
          • Collateral Provision: Deposited funds are considered collateral that the Client provides to secure obligations to the Company.
          </p>

          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">User Conduct and Restrictions</h3>
          <p className="mt-2">
          • The Client shall not engage in activities that breach the security, integrity, or operation of the Trading Platform. <br/>
          • The Client is prohibited from executing transactions that exceed their available Account balance or plan. Such actions may result in penalties, suspension, or permanent account termination. <br/>
          </p>
        </div>

        <div>
          <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
            Account Types and User Privileges
          </h2>
          <p className="mt-2">
            The Company offers a tiered account system that progresses in conjunction with the Client’s investment plan. Each account type has an associated profit threshold/cap, 
            determining the Client’s eligibility for an investment plan upgrade. The details for each account plan are as follows:
          </p>
          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Starter Account</h3>
          <p className="mt-2">
            • Trading Features: Access to basic stock pairs trading and limited copy trading. <br/>
            • Staking Options: Limited staking options with lower APY rates. <br/>
            • Profit Threshold: $49,999.99. <br/>
            • Support Level: Email-only support. <br/>
            • Fees: Standard trading and staking fees. <br/>
          </p>

          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Silver Account</h3>
          <p className="mt-2">
            • Trading Features: Expanded access to more stock pairs and enhanced copy trading options. <br/>
            • Staking Options: Access to a wider range of staking options with improved APY. <br/>
            • Profit Threshold: $119,999.99. <br/>
            • Support Level: Priority email support with quicker response times. <br/>
            • Fees: Reduced trading and staking fees. <br/>

          </p>

          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Gold Account</h3>
          <p className="mt-2">
            • Trading Features: Full access to all stock pairs and comprehensive copy trading metrics.<br/>
            • Staking Options: Premium staking opportunities with flexible terms and higher APY.<br/>
            • Profit Threshold: $249,999.99.<br/>
            • Support Level: Priority email and live chat support.<br/>
            • Fees: Further reduced trading and staking fees.<br/>

          </p>

          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Premium Account</h3>
          <p className="mt-2">
            • Trading Features: Full platform access, including advanced copy trading features with real-time syncing. <br/>
            • Staking Options: Access to high-priority staking opportunities with top APY rates.<br/>
            • Profit Threshold: $549,999.99.<br/>
            • Support Level: 24/7 support through live chat and phone.<br/>
            • Educational Resources: Comprehensive access to webinars, courses, and expert-led workshops.<br/>
            • Fees: Preferential fee structures with lower charges for trading and staking.<br/>
          </p>

          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Platinum Account</h3>
          <p className="mt-2">
            • Trading Features: Unlimited access to proprietary tools, advanced charting, and AI-powered analytics.<br/>
            • Copy Trading: Exclusive access with unique features such as revenue-sharing for top traders.<br/>
            • Staking Options: VIP access to top-tier staking programs with flexible terms and the highest APY available.<br/>
            • Profit Threshold: $999,999.99 (awarded based on account activities).<br/>
            • Support Level: Concierge-level, 24/7 support with a dedicated personal manager.<br/>
            • Educational Resources: Personalized training programs, one-on-one mentorship, and invitation-only seminars.<br/>
            • Portfolio Management: Customized investment consultation services.<br/>
            • Fees: No trading fees and access to special loyalty incentives and rebates.<br/>
            • Exclusive Benefits: Invitations to elite events, closed beta program access, and co-development opportunities.<br/>
          </p>

        </div>

        <div>
          <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
            Execution of Orders / Electronic Trading
          </h2>
          <p className="mt-6">
            The Client acknowledges that all orders will be executed by the Company as a counterparty. 
            The Company acts as a principal, not an agent, for these transactions. Orders are processed sequentially and promptly 
            unless technical or market-related issues arise. The Company is not responsible for unauthorized use of the Client’s 
            Account or for errors due to mechanical failures, except in cases of gross negligence or fraud by the Company.
          </p>
        </div>

        <div>
          <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
            Charges and Fees
          </h2>
          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">General Fee Structure</h3>
          <p className="mt-2">
            The Company reserves the right to charge fees for services, including but not limited to:<br/>

            • Service Fees: Standard charges for accessing trading and staking services.<br/>
            • COT (Cost of Transfer): Variable fees that depend on withdrawal criteria such as blockchain congestion and transaction size.<br/>
            • Third-party Fees: Charges related to third-party service providers.<br/>
            • Regulatory Compliance Costs: Fees for necessary verification and compliance checks.<br/>

          </p>

          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Staking and Conversion Fees</h3>
          <p className="mt-2">
            • Staking Fees: A fixed fee of 1.25% on the total staked amount.<br/>
            • Conversion Fees: Free of charge for currency conversions within the platform.<br/>

          </p>

          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Changes to Fees</h3>
          <p className="mt-2">
            The Company may revise its fee structure and will notify Clients of such changes via the Website or email. 
            Continued use of the services implies acceptance of the new fees.

          </p>
        </div>

        <div>
          <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
            Withdrawal Policy
          </h2>
          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">General Withdrawal Terms</h3>
          <p className="mt-2">
            Withdrawals cannot be initiated while there are active trade positions in the Client’s Account.
          </p>

          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Processing Time and Criteria</h3>
          <p className="mt-2">
            Standard processing for withdrawal requests is up to 3 business days, influenced by factors such as blockchain traffic and withdrawal size.

          </p>

          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Withdrawal Fees</h3>
          <p className="mt-2">
            The Client is responsible for all withdrawal-related fees. These fees must be settled at the time of the request and 
            are not deducted from the Client’s portfolio balance.
          </p>

          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Withdrawal Holds</h3>
          <p className="mt-2">
            For compliance and security reasons, certain withdrawal requests may be subject to review and may 
            be temporarily held for verification purposes.
          </p>
        </div>

        <div>
          <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
            Investment Plan Limitations
          </h2>
          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Profit Thresholds</h3>
          <p className="mt-2">
            The four investment plan tiers has defined profit thresholds. If the Client’s total portfolio profits 
            exceed these thresholds (as specified in Section 6), the account may be temporarily suspended, 
            with a notification to upgrade the plan or make adjustments.
          </p>

          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Non-Compliance Penalties</h3>
          <p className="mt-2">
            Clients who exceed their investment plan’s profit cap will receive notifications detailing necessary actions, 
            including potential penalties or suspension until compliance is achieved.
          </p>
        </div>

        <div>
          <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
            Signal Purchase Terms and Conditions
          </h2>
          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Leverage Limits and Compliance</h3>
          <p className="mt-2">
            Each signal plan includes a pre-set leverage range. The Client agrees not to open trades that exceed these leverage limits. 
            Violating these limits will result in account suspension and prompts to upgrade the signal plan for compliance.
          </p>

          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Consequences of Non-Compliance</h3>
          <p className="mt-2">
            Repeated violations may result in longer account suspensions or permanent restrictions.
          </p>
        </div>

        <div>
          <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
            Limitation of Liability
          </h2>
          <p className="mt-2">
            The Company does not guarantee uninterrupted, error-free services and disclaims liability for service disruptions due to external factors, 
            including but not limited to third-party failures or force majeure events. Liability for direct or indirect damages 
            shall not exceed the amount of funds deposited by the Client, except in cases of gross negligence or fraudulent actions by the Company.
          </p>
        </div>

        <div>
          <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
            Indemnity and Liability
          </h2>
          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Client Indemnification</h3>
          <p className="mt-2">
            The Client agrees to indemnify and hold harmless the Company, its directors, officers, employees, 
            and representatives against any direct or indirect liabilities, losses, damages, claims, or expenses incurred as a 
            result of the Client’s actions or omissions in violation of this Agreement or related to the liquidation of financial instruments. This indemnity extends beyond the termination of the Agreement.
          </p>

          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Company’s Limited Liability</h3>
          <p className="mt-2">
            The Company shall not be liable for any direct or indirect losses, including loss of profit, trading opportunity, 
            or any incidental, consequential, or special damages arising from the use of the Trading Platform, 
            except in cases of proven gross negligence or willful misconduct by the Company.
          </p>

          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Third-Party Service Liability</h3>
          <p className="mt-2">
            The Company shall not be held responsible for actions, omissions, or failures of third-party service providers, 
            including payment processors, custodians, and other intermediaries involved in transactions on behalf of the Client.
          </p>
        </div>

        <div>
          <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
            Personal Data Protection
          </h2>
          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Data Collection and Usage</h3>
          <p className="mt-2">
            By accepting this Agreement, the Client consents to the collection, storage, and processing of personal data, 
            which includes but is not limited to names, contact details, transaction history, and IP addresses. 
            The Company collects this information to comply with regulatory requirements, facilitate transactions, and improve services.
          </p>

          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Data Security</h3>
          <p className="mt-2">
            The Company shall implement legal, organizational, and technical measures to protect the Client’s personal 
            data against unauthorized access, loss, or alteration. Data will be retained for at least 5 years 
            after the termination of the Agreement to meet regulatory obligations.
          </p>

          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Third-Party Sharing </h3>
          <p className="mt-2">
            The Company may share personal data with third parties solely for processing transactions or complying with legal obligations, 
            provided that these parties agree to protect the confidentiality and security of the data.
          </p>
        </div>

        <div>
          <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
            Duration and Termination of the Agreement
          </h2>
          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Indefinite Duration</h3>
          <p className="mt-2">
            This Agreement shall remain in effect for an indefinite period from the date of acceptance and activation of the Client’s Account.
          </p>

          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Termination by the Client</h3>
          <p className="mt-2">
            The Client may terminate this Agreement at any time by notifying the Company in writing and withdrawing any 
            remaining balance in their Account, subject to compliance checks and applicable fees.
          </p>

          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Termination by the Company</h3>
          <p className="mt-2">
            The Company reserves the right to terminate the Agreement or suspend services with immediate effect if the Client fails to meet 
            regulatory requirements, breaches any term of this Agreement, or is involved in fraudulent activities.
          </p>

          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Effect of Termination</h3>
          <p className="mt-2">
            Upon termination, the Client’s access to the Trading Platform will be revoked, and any pending transactions will be cancelled. 
            The Company will remit any remaining balance, minus applicable fees and unresolved liabilities.
          </p>

        </div>

        <div>
          <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
            Dispute Resolution
          </h2>
          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Jurisdictional Guidelines</h3>
          <p className="mt-2">
            Disputes involving Clients based in the U.S., EU, or Australia shall be resolved according to the laws and courts of the Client’s 
            country of residence. Clients residing outside these jurisdictions agree to submit to the exclusive jurisdiction of the 
            New York State Supreme Court.
          </p>

          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Arbitration Option</h3>
          <p className="mt-2">
            For certain disputes, the Company reserves the right to propose resolution through binding arbitration, 
            conducted in accordance with the rules of an agreed-upon arbitration body.
          </p>
        </div>

        <div>
          <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
            Technical Failures
          </h2>
          <p className="mt-2">
            The Client acknowledges that technical failures, including system outages or disruptions due to hardware, software, 
            or communication issues, may occur. In such cases, the Company shall make reasonable efforts to resolve issues swiftly 
            but shall not be held liable for trading interruptions or loss of data.
          </p>

          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Client Guidelines During Failures</h3>
          <p className="mt-2">
            Clients are advised to remain patient and avoid making sudden changes to their accounts during technical failures. 
            The Company’s support team is available to assist with inquiries related to technical disruptions.
          </p>
        </div>

        <div>
          <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
            User Support
          </h2>
          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Support Channels</h3>
          <p className="mt-2">
            Clients can reach out for support through support@pairdex.io. 
            Premium and Platinum account holders have additional options, including phone support and live chat.
          </p>

          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Response Time</h3>
          <p className="mt-2">
            The Company commits to addressing support requests within reasonable response times, 
            prioritizing Premium and Platinum account holders for expedited assistance.
          </p>
        </div>

        <div>
          <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
            Amendments to the Agreement
          </h2>
          <p className="mt-2">
            The Company reserves the right to amend or revise the terms of this Agreement at its discretion. 
            Changes will be communicated via the Website or email, and continued use of the 
            Trading Platform after any such amendments constitutes acceptance of the new terms.
          </p>
        </div>

        <div>
          <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
            Assignment
          </h2>
          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Non-Transferability by Client</h3>
          <p className="mt-2">
            The Client may not assign or transfer any rights or obligations under this Agreement without the prior written consent of the Company.
          </p>

          <h3 className="mt-5 text-xl font-[600] tracking-tight text-gray-900">Company’s Right to Assign</h3>
          <p className="mt-2">
            The Company may transfer or assign its rights and obligations under this 
            Agreement to a third party, provided that it notifies the Client.
          </p>
        </div>

        <div>
          <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
            Acknowledgement and Acceptance of Risk
          </h2>
          <p className="mt-2">
            The Client acknowledges that trading, cryptocurrency staking, and other related activities involve significant risk, 
            including the loss of the principal amount invested. By using the services, the Client accepts these risks and 
            agrees not to hold the Company liable for potential losses.
          </p>
        </div>





        {/* <div>
          <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
            Services Of The Company
          </h2>
          <p className="mt-6">
            Services – services provided by the Company to the Client through
            the Trading Platform of the Company, including without limitation to
            customer, analytics, news and marketing information services. The
            Company shall facilitate the execution of trade activities/orders
            and/or transactions of the Client but the Client hereby acknowledges
            and accepts that the Company shall not at any time provide any trust
            services and/or trading consultation or advisory services to the
            Client. The Company shall process all transactions/Operations of the
            Client in accordance with the terms and conditions of this Agreement
            and on an execution-only basis. The Company shall not manage the
            Client’s Account nor advise the Client in any way. The Company shall
            process the orders/transactions requested by the Client under this
            Agreement irrespective of whether such orders/transactions may
            result to not being beneficial for the Client. The Company is under
            no obligation, unless otherwise agreed in this Agreement and/or
            other documentation/information on the Website, to monitor or advise
            the Client on the status of any transaction/order, to make margin
            calls to the Client, or to close out any of the Client’s open
            positions. Unless otherwise specifically agreed, the Company is not
            obligated to process or attempt to process the Client’s
            order/transaction using quotes more favorable than those offered
            through the Trading Platform. The Company shall not be financially
            liable for any operations conducted by the Client through the
            Account and/or on the Trading Platform. Each Client shall be the
            only authorized user of the Company’s services and of the
            corresponding Account. The Client is granted an exclusive and
            non-assignable right to the use of and to access the Account and it
            is his/her responsibility to ensure that no other third party,
            including, without limitation, to any next of kin and/or to members
            of his/her immediate family, shall gain access to and/or trade
            through the Account assigned to her/him. The Client shall be liable
            for all orders given through his security information and any orders
            received in this manner by the Company shall be considered to have
            been given by the Client. So long as any order is submitted through
            the Account of a Client, the Company shall reasonably assume that
            such orders are submitted by Client and the Company shall not be
            under any obligation to investigate further into the matter. The
            Company shall not be liable to and/or does not maintain any legal
            relations with, any third party other than the Client. If the Client
            acts on behalf of any third party and/or on behalf of any third
            party’s name, the Company shall not accept this person as a Client
            and shall not be liable before this person regardless if such person
            was identified or not. The Client has the right to cancel his order
            given to the Company within 3 seconds after the moment of giving
            such order to the Company (hereinafter referred to as the
            “Cancellation”). The client agrees and understands that the three
            seconds cancellation option offered by the Company is applicable and
            available for the client as long as the price remains unchanged.
            Three seconds from the moment of giving the order to the Company by
            the Client via the platform, the Company may (but is not obliged to)
            offer to buyout the option from the Client and the Client have the
            right to agree to such offer (hereinafter referred to as the
            “Buyout”). The Client is entitled to use such Cancellation or Buyout
            option subject to the conditions specified on the platform. Such
            conditions can also include the fee charged by the Company. Such fee
            is specified on the platform. The Company is obliged to provide all
            necessary information as to the conditions of Cancellation and
            Buyout, their cost, etc. The Client acknowledges and agrees that
            provision of such information on the platform is sufficient. The
            Client acknowledges and agrees that the use of Cancellation or
            Buyout is very risky to the Client as long as the cost of
            Cancellation and/or Buyout depends on the market situation. The
            Client acknowledges and agrees that he bears all the risks
            associated with the use of Cancellation and/or Buyout. The Client is
            entitled to use such Cancellation or Buyout option subject to the
            conditions specified on the Trading Platform/Website, including
            without limitation to any fee to be charged by the Company. The
            Company shall be obliged to provide all necessary information as to
            the conditions of Cancellation and Buyout, including any applicable
            costs, etc. The Client acknowledges, accepts and agrees that
            provision of such information on the Trading Platform is sufficient.
            The Client acknowledges, accepts and agrees that the use of
            Cancellation or Buyout option entail large risks for the Client,
            especially in the case where the costs associated with Cancellation
            and/or Buyout, depend on the market situation. The Client
            acknowledges, accepts and agrees that he/she shall bear all risks
            associated with the use of Cancellation and/or Buyout option. It is
            understood and agreed by the Client that the Company may from time
            to time, at its sole discretion, utilize a third party to hold the
            Client’s funds and/or for the purpose of receiving payment execution
            services. These funds will be held in segregated accounts from such
            third party’s own funds and will not affect the rights of the Client
            to such funds. The Company offers internal live chats where clients
            can share inter alia their trading ideas and/or express their
            general thoughts. The client acknowledges and agrees that the
            Company’s live chat feature is not and will not constitute a valid
            and/or accurate information and/or information addressed to the
            clients/potential clients and/or in any way information that is
            controlled by the Company and/or investment advice, as it is merely
            a feature allowing clients to inter alia express their thoughts and
            ideas between themselves. Provision of investment advice shall only
            be carried out by the Company subject to a separate written
            agreement with the Client and after assessing the Client’s personal
            circumstances. Unless such written agreement has been entered into
            between the Client and the Company, the provision of reports, news,
            opinions and any other information by the Company to the Client does
            not constitute investment advice or investment research.
          </p>
        </div>

        <div>
          <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
            Execution Of Orders / Electronic Trading
          </h2>
          <p className="mt-6">
            By accepting this Agreement, the Client accepts that he has read and
            understood all provisions of this Agreement and related information
            on the Website. The Client accepts and understands that all orders
            received shall be executed by the Company as the counterparty of the
            transaction in its capacity of Market Maker. The Company shall act
            as a principal and not as an agent on the Client’s behalf for the
            purpose of the Execution of orders. The Client is informed that
            Conflicts of Interest may arise because of this model. Reception of
            the order by the Company shall not constitute acceptance and
            acceptance shall only be constituted by the execution of the order
            by the Company. The Company shall be obliged to execute the Client's
            orders sequentially and promptly. The Client acknowledges and
            accepts a) the risk of mistakes or misinterpretations in the orders
            sent through the Trading Platform due to technical or mechanical
            failures of such electronic means, b) the risk of any delays or
            other problems as well as c) the risk that the orders may be placed
            by persons unauthorised to use and/or access the Account, and the
            Client agrees to indemnify the Company in full for any loss incurred
            as a result of acting in accordance with such orders. The Client
            accepts that during the reception and transmission of his/her order,
            the Company shall have no responsibility as to its content and/or to
            the identity of the person placing the order, except where there is
            gross negligence, willful default or fraud by the Company. The
            Client acknowledges that the Company will not take action based on
            the orders transmitted to the Company for execution by electronic
            means other than those orders transmitted using the predetermined
            electronic means such as the Trading Platform, and the Company shall
            have no liability towards the Client for failing to take action
            based on such orders. The client acknowledges and agrees that any
            products or services that may be offered by the Company may not
            always be available for purchasing or use for trading purposes, and
            it is in the Company's absolute discretion whether it will make
            these products available or not to the clients at any time. The
            Company shall bear no liability, monetary or otherwise, in relation
            to this section, including without limitation to not making
            available any product at any given time.
          </p>
        </div>

        <div>
          <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
            Limitation Of Liability
          </h2>
          <p className="mt-6">
            The Company does not guarantee uninterrupted service, safe and
            errors-free, and immunity from unauthorized access to the trading
            sites' servers nor disruptions caused from damages, malfunctions or
            failures in hardware, software, communications and systems in the
            Client's computers and in the Company's suppliers. Supplying
            services by the Company depends, inter alia, on third parties and
            the Company bears no responsibility for any actions or omissions of
            third parties and bears no responsibility for any damage and/or loss
            and/or expense caused to the Client and/or third party as a result
            of and/or in relation to any aforesaid action or omission. The
            Company will bear no responsibility for any damage of any kind
            allegedly caused to the Client, which involves force majeure or any
            such event that the Company has no control of and which has
            influenced the accessibility of its trading site. Under no
            circumstances will the Company or its Agent(s) hold responsibility
            for direct or indirect damage of any kind, even if the Company or
            its Agent(s) had been notified of the possibility of aforesaid
            damages.
          </p>
        </div>

        <div>
          <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
            Settlement Of Transactions
          </h2>
          <p className="mt-6">
            The Company shall proceed to a settlement of all transactions upon
            execution of such transactions. An online statement of Account will
            be available for printing to the Client on the Trading Platform of
            the Company, at all times.
          </p>
        </div>

        <div>
          <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
            Indemnity And Liability
          </h2>
          <p className="mt-6">
            The Client shall indemnify and keep indemnified the Company and its
            directors, officers, employees or representatives against all direct
            or indirect liabilities (including without limitation all losses,
            damages, claims, costs or expenses), incurred by the Company or any
            other third party in respect to any act or omission by the Client in
            the performance of his/her obligations under this Agreement and/or
            the liquidation of any financial instruments of the Client in
            settlement of any claims with the Company, unless such liabilities
            result from gross negligence, willful default or fraud by the
            Company. This indemnity shall survive termination of this Agreement.
            The Company shall not be liable for any direct and/or indirect loss,
            expense, cost or liability incurred by the Client in relation to
            this Agreement, unless such loss, expense, cost or liability is a
            result of gross negligence, willful default or fraud by the Company.
            Notwithstanding the provisions of section 8.1 above, the Company
            shall have no liability to the Client whether in tort (including
            negligence), breach of statutory duty, or otherwise, for any loss of
            profit, or for any indirect or consequential loss arising under
            and/or in connection with the Agreement. The Company shall not be
            liable for any loss of opportunity as a result of which the value of
            the financial instruments of the Client could have been increased or
            for any decrease in the value of the financial instruments of the
            Client, regardless of the cause, unless such loss is directly due to
            gross negligence, willful default or fraud on the part of the
            Company. The Company shall not be liable for any loss which is the
            result of misrepresentation of facts, error in judgment or any act
            done or which the Company has omitted to do, whenever caused, unless
            such act or omission resulted from gross negligence, willful default
            or fraud by the Company. The Company shall not be liable for any act
            or omission or for the insolvency of any counterparty, bank,
            custodian or other third party which acts on behalf of the Client or
            with or through whom transactions on behalf of the Client are
            carried out.
          </p>
        </div>

        <div>
          <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
            Personal Data
          </h2>
          <p className="mt-6">
            By accepting the terms and conditions of this Agreement, the Client
            irrevocably consents to the collection and processing of his/her
            personal data/information by the Company without the use of
            automatic controls, as the same are provided by him/her to the
            Company. The term personal data for the purposes of this Agreement
            shall mean: the Name, Surname, Patronymic, gender, address, phone
            number, e-mail, IP address of the Client, Cookies and information
            that relate to the provision of Services to the Client (for example,
            the Client’s trading story). The Client shall be obliged to provide
            correct, accurate and complete personal data/information as
            requested by the Company. The purpose of collecting and processing
            the personal data is to comply with applicable regulating
            legislation requirements, including without limitation to anti-money
            laundering regulations, as well as for any and all purposes in
            relation to this Agreement, including without limitation to enable
            the Company to discharge its obligations towards the Client. The
            Client acknowledges and consents to that, for the purposes described
            at the section directly above, the Company shall be entitled to
            collect, record, systematize, accumulate, store, adjust (update,
            change), extract, use, transfer (disseminate, provide, access),
            anonymize, block, delete, destroy such personal data and/or perform
            any other actions according to the current regulating legislation.
            The Client acknowledges and consents to the Company storing,
            maintaining and processing his/her personal data in the manner as
            described in this Agreement during the term of the Agreement and for
            5 years following any termination of the Agreement. The Client
            hereby acknowledges, accepts, agrees and consents to the disclosure
            of personal data by the Company to third parties and their
            representatives, solely for the purposes of the Agreement, including
            without limitation in order to facilitate processing/execution of
            the Client’s orders/Operations, provided that at all times (i) the
            amount of personal data to be disclosed to any such third party is
            proportionate and/or limited solely to facilitate to the actions as
            described above, and (ii) the Company shall ensure that such third
            party shall treat the personal data in accordance with applicable
            laws and regulations. The Company shall not be entitled to make
            available the personal data in public and/or disclose such personal
            data for any other purposes, subject to disclosure required under
            applicable laws and regulations. During processing of the personal
            data, the Company shall take necessary legal, organizational and
            technical measures to protect such personal data from unauthorized
            or accidental access, destruction, change, blocking, copying,
            provision, and dissemination as well as from any other illegal
            actions.
          </p>
        </div>

        <div>
          <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
            Assignment
          </h2>
          <p className="mt-6">
            The Agreement shall be personal to the Client and the Client shall
            not be entitled to assign or transfer any of his/her rights or
            obligations under this Agreement. The Company may at any time assign
            or transfer any of its rights or obligations under this Agreement to
            a third party. The Company shall notify the Client of any such
            assignment.
          </p>
        </div>

        <div>
          <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
            Assignment
          </h2>
          <p className="mt-6">
            The Client hereby confirms to have read, understood and hereby
            accepts the risk statement relating to the use of Services on the
            Website, as the same is available electronically via the Website.
            <br />
            <br />
            The Client hereby confirms to have read, understood and hereby
            accepts the risk statement relating to the use of Services on the
            Website, as the same is available electronically via the Website.
          </p>
        </div>

        <div>
          <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
            Charges And Fees
          </h2>
          <p className="mt-6">
            The Company shall be entitled to receive a fee from the Client
            regarding the Service(s), provided by the Company. The Company may
            pay fee/commission to business introducers, referring agents, or
            other third parties based on written agreement. This fee/commission
            is related to the frequency/volume of transactions and/or other
            parameters. The Company may pay fee/commission to business
            introducers, referring agents, or other third parties based on
            written agreement. This fee/commission is related to the
            frequency/volume of transactions and/or other parameters. All
            applicable fees or charges can be found on the Company’s Website
            (General Fees). The Company has the right to amend its fees and
            charges from time to time. Ongoing trading fees, including inter
            alia swaps, shall be charged and deducted from the Client’s account
            balance. In case the Client does not maintain enough funds in
            his/her balance, the relevant position subject to swap will be
            closed by the Company. The Client agrees that any amounts sent by
            the Client will be deposited to the Account at the value on the date
            of the payment received and net of any charges / fees charged by the
            bank or any other intermediary involved in such transaction process
            and/or in any other case, the Client shall authorize the Company to
            withdraw the fee by way of transfer from the Client’s Account.
          </p>
        </div>

        <div>
          <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
            Duration And Termination Of The Agreement
          </h2>
          <p className="mt-6">
            The Agreement herein shall be concluded for an indefinite term. The
            Agreement herein shall come into force when the Client accepts the
            Agreement and makes an advance payment to the Company. In case of
            any discrepancies between the text of the Agreement in English and
            its translation in any other language, the text of the Agreement in
            English as a whole shall prevail, as well as the English
            version/text of any other documentation/information published on the
            Website. The Company shall be entitled to terminate this Agreement
            immediately without giving prior notice if the Client fails to
            provide to the Company his/her KYC documents within 14 days from the
            moment of acceptance of this Agreement, constituting, thus, his/her
            Account as an unverified Account. In case of termination of this
            Agreement for a reason indicated in section 15.b, subclauses i, ii
            and x of this Agreement the Company shall have no liability towards
            the Client and no obligation to pay the profit of the Client (if
            any). In case of termination of this Agreement for a reason
            indicated in sections 15.a of this Agreement, the Company shall have
            either to wire to the Client the remaining balance or to give to the
            Client the opportunity to withdraw his/her remaining balance. In
            case of termination of this Agreement for a reason indicated in
            section 16.b of this Agreement, the Company shall have to wire to
            the Client the remaining balance excluding any profit.
          </p>
        </div>

        <div>
          <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
            Terms And Conditions
          </h2>
          <p className="mt-6">
            The Client shall agree to make a deposit to his/her Account to use
            the Company Services or any other additional services ordered by the
            Client on the Website as well as all additional expenses (if
            necessary), including but not limited to any taxes, duties, etc. The
            Client shall be completely responsible for timely depositing the
            funds into his/her Account. Provider of payment services shall
            ensure only fulfillment of payment in the amount defined by the Site
            and shall not be liable for payment of the above-mentioned
            additional amounts by the Website’s Client. The payment is
            considered to be processed and cannot be returned after clicking the
            “Payment” button. By clicking the “Payment” button, the Client shall
            agree that he/she cannot return the payment or require its recall.
            Additionally, by accepting the terms and conditions herein contained
            , the Client as the owner of the payment card confirms that he/she
            shall be entitled to use the Services offered on the Website. By
            accepting the terms and conditions of this Agreement and depositing
            funds to the Account, the Client agrees to the use the Website’s
            Services and accepts that the processing of any of the Client’s
            payment shall be executed by a provider of payment services, being a
            third party to this Agreement (the “Provider”), and the Client
            further acknowledges and accepts that no legal right exists for
            return of already purchased Services or other options of payment
            cancellation. In case if the Client is willing to refuse from using
            the 1-Click service for the next purchase of the Service, the Client
            can refuse from 1-Click service using the Account on the Website.
            Note that 1-click deposits (recurring payments) are not processed as
            3-D secure transactions, the client needs to enable 3-D secure
            function if he would like the payments to be processed as 3-D
            secure, as it's vital information in regards to BTC withdrawal
            policy. The Provider shall not be in any case liable for the
            refusal/impossibility to process the data connected with payment
            card of the Client, or for the refusal connected with failure to
            obtain permission from the issue bank to process payment using the
            payment card of the Client. The Provider shall not be in any case
            liable for quality, amount, and price of any service, offered to the
            Client or purchased by the Client of the Website using the payment
            card of the Client. Paying for any Services of the Website the
            Client first of all shall be obliged to fulfill the rules of using
            the Website. We are asking to consider that only the Client as the
            owner of the payment card shall be liable for timely payment of any
            service ordered via the Website and for all additional expenses/fees
            connected with this payment. The Provider shall only be the
            performer of payment in the amount specified by the Website and
            shall not be in any case liable for any pricing, general prices
            and/or total sums. In case of the situation connected with the
            Client’s dissent with the terms mentioned above and/or any other
            reasons, we are asking the Client to promptly refuse from making a
            payment and to directly address the administrator/support of the
            Website if necessary.
          </p>
        </div>

        <div>
          <h2 className="mt-16 text-2xl font-[600] tracking-tight text-gray-900">
            The Client’s Responsibility
          </h2>
          <p className="mt-6">
            The Client acknowledges that these General Terms are an integral
            part of this Agreement. It is the Client's responsibility to verify
            that all transactions and Service(s) received are not contradictory
            to any applicable law and to undertake any other legal duty
            emanating from the use of Website at the Client’s sole option,
            discretion and risk, and the Client is solely responsible for
            ascertaining whether it is legal in the Client's jurisdiction and/or
            place of residence. The Client holds sole liability for all
            transactions in his Trading Account, including all cards
            transactions or other means of deposit and withdrawal transactions
            (as stated below). The Client acknowledges that the Company reserves
            the right to accept or decline any deposit and/or funding and/or
            withdrawal request by the Client depending on the payment method
            that the Client chooses (which includes but is not limited to the
            third party financial institution from which the Client wishes to
            deposit/withdraw funds with the Company (Third Party Institutions)),
            and the Company may suggest to the Client an alternative for its
            request. It is important to note that the Company does not have and
            cannot in any way have any, control over such Third Party
            Institutions and any transactions made by the Client through the
            Platform using such institutions and it is hereby acknowledged and
            agreed that the Company shall bear no liability, monetary or
            otherwise, in relation to any loss of funds incurred by the Client
            pursuant to any actions or omissions of Third Party Institutions.
            The Client is responsible for securing his/her Username and Password
            for his Trading Account. The Client holds sole responsibility for
            any damage caused due to any act or omission of the Client causing
            inappropriate or irregular use of the Client Trading Account. It is
            clearly stated and agreed by the Client that the Client bears sole
            responsibility for any decision made and/or to be made by the Client
            relying on the content of the Website and no claim and/or suit of
            any kind will arise to that effect against the Company and/or its
            directors and/or employees and/or functionaries and/or Agents (the
            Company and/or its Agents). The Company and/or its Agents will hold
            no responsibility for loss of profits due to and/or related to the
            Website, Transactions carried out by the Client, Services and the
            General Terms of use or any other damages, including special damages
            and/or indirect damages or circumstantial damages caused, except in
            the event of malicious acts made by the Company. Without limitation
            of the aforesaid and only in the event of definitive judgment by
            court or other authorized legal institution resolving that the
            Company and/or its Agent(s) hold liability towards the Client or
            third party, the Company's liability, in any event, will be limited
            to the amount of money deposited and/or transferred by the Client to
            the Trading Account in respect of the transaction which caused the
            liability of the Company and/or its Agent(s) (if such was caused).
            No Trading Account will be approved without the completion of the
            Company’s compliance procedures, including the identification and
            verification of the Account.
          </p>
        </div> */}
        {/* <div className="mt-10 max-w-4xl">
          
          </div> */}

        {/* <div className="mt-16 max-w-2xl">
            <h2 className="text-2xl font-[600] tracking-tight text-gray-900">
              Everything you need to get up and running
            </h2>
            <p className="mt-6">
              This Privacy Policy (the “Policy” ) applies to Brass Technologies
              Limited’s online interface (i.e., website or mobile application) and
              any Brass Invoicing Limited’s affiliate, subsidiary or online
              interface that is linked to this Policy, (individually referred to
              as a “site”, and, collectively, “sites” ). The term “the company” or
              "Brass" or "we" or "us" or "our" in this Policy shall refer to Brass
              Invoicing Limited, and any banking and non-banking affiliates or
              subsidiaries of Brass Invoicing Limited that is linked to this
              Policy. This Policy describes how the Sites may collect, use, and
              share information from or about you, and explains how information
              may be collected and used for advertising purposes.
            </p>
            <p className="mt-8">
              Brass takes the privacy of your information very seriously. This
              Policy explains how and for what purposes we may use the information
              collected from you via the Site.
            </p>
  
            <p className="mt-8">
              Additional information on our Privacy Policy may be found on our
              Sites and within Frequently Asked Questions (FAQs) as set out on the
              Sites.
            </p>
  
            <p className="mt-8">
              Please read this Policy carefully. By using the Site and any
              services we offer via the Site, you are agreeing to be bound by this
              Policy with respect to the information collected about you via this
              Site.
            </p>
  
            <p className="mt-8">
              You may use the Site only for lawful purposes. You may not use the
              Site:
            </p>
  
            <ul>
              <li>
                In any way that breaches any applicable local, national or
                international law or regulation.
              </li>
            </ul>
          </div> */}

        {/* <div className="mt-16 max-w-2xl">
            <h2 className="text-2xl font-[600] tracking-tight text-gray-900">
              Everything you need to get up and running
            </h2>
            <p className="mt-6">
              Purus morbi dignissim senectus mattis adipiscing. Amet, massa quam
              varius orci dapibus volutpat cras. In amet eu ridiculus leo sodales
              cursus tristique. Tincidunt sed tempus ut viverra ridiculus non
              molestie. Gravida quis fringilla amet eget dui tempor dignissim.
              Facilisis auctor venenatis varius nunc, congue erat ac. Cras
              fermentum convallis quam.
            </p>
            <p className="mt-8">
              Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus
              enim. Mattis mauris semper sed amet vitae sed turpis id. Id dolor
              praesent donec est. Odio penatibus risus viverra tellus varius sit
              neque erat velit.
            </p>
          </div> */}
      </div>
    </div>
  );
}
